// PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE
// PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE
// PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE PAGE

.page-wrapper {
  width: 100%;
  margin: 0 auto;
  height: calc(100vh);

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  .page-container {
    display: flex;
    justify-content: center;

    background-image: url("/img/blue_background.jpg");
    background-position: top center;
    background-size: cover;
    background-repeat: repeat;
    //background-attachment: fixed;

    overflow: hidden;

    &.page-int {
      background-image: url("/img/background-int.jpg");
    }

    .page-content {
      display: flex;
      flex: 1 1 auto;
      min-height: 100vh;
      // max-width: 62.5rem; //1000px orizzontale
      // max-width: 102rem; //1640px orizzontale

      justify-content: center;
      align-items: center;
      flex-flow: column;

      position: relative;
      z-index: 500;

      .top-area {
        width: 100%;
        flex: 0 1 auto;

        &.has-header {
          height: 6rem;
        }
      }

      &.col-white-themed {
        color: #000 !important;
        font-size: 1.1rem;

        a {
          color: #000;
        }
      }

      .center-area {
        display: flex;
        flex: 2 1 auto;
        width: 100%;

        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: transparent;

        position: relative;
        z-index: 500;
      }

      .bottom-area {
        width: 100%;
        flex: 0 1 auto;
      }
    }
  }
}

.columns-wrapper {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  .box {
    & > img {
      width: auto;
      height: auto !important;
      display: block;
    }

    &.logo-wrapper {
      padding-top: 12rem;
    }
  }

  &.justify-center {
    justify-content: center;
  }
}
