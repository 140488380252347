.container-player {
  float: right;
}
.container-section {
  align-items: center;
  -webkit-transition: all 0.6s ease-in-out !important;
}
.container-txt {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 14px;
}
.title-sec-player {
  font: normal normal normal 12px/24px Arial;
  color: red;
  font-size: 25px;
  font-weight: bold;
}
.subtitle-sec-player {
  font: normal normal normal 12px/24px Arial;
  color: white;
  font-size: 16px;
  margin-top: 6px !important;
}
#subtitle-2-span {
  font: normal normal normal 12px/24px Arial;
  color: red;
  font-size: 16px;
  margin-top: 10px !important;
}
.subtitle-2-player {
  font: normal normal normal 12px/24px Arial;
  color: white;
  font-size: 16px;
}
.text-player {
  font: normal normal normal 12px/24px Arial;
  color: white;
  font-size: 16px;
  margin-top: 10px !important;
}
.text2-player {
  font: normal normal normal 12px/24px Arial;
  color: red;
  font-size: 16px;
  margin-top: 14px !important;
}
.text3-player {
  font: normal normal normal 12px/24px Arial;
  color: white;
  font-size: 14px;
}
.player-container {
  float: right;
}
#container-video-box {
  margin-left: 10px !important;
}
.video-box {
  // margin-left: 10px !important;
  width: 100% !important;
  height: 100% !important;
  border: 5px solid transparent !important;
  &:hover {
    box-shadow: 0px 0px 6px 2px #ff000077;
  }
}
.active-video-box {
  -webkit-transition: all 0.6s ease-in-out !important;
  // margin-left: 10px!important;
  width: 100% !important;
  height: 100% !important;

  border: 5px solid red !important;
}
