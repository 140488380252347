// Plenary Chatroom Plenary Chatroom Plenary Chatroom Plenary Chatroom Plenary Chatroom Plenary Chatroom Plenary Chatroom
.plenary-chat-wrapper {
  min-height: 26.2rem;

  .chatroom-wrapper {
    background-color: $color-primary-alt;

    .back-button {
      font-family: "Gotham", sans-serif;
      font-weight: 350;
      color: $color-white;
      font-size: 1.5rem;

      background-color: transparent;
      border: 0;
      padding: 0.5rem 0 2rem;

      &:hover {
        cursor: pointer;
      }

      img {
        margin-right: 0.5rem;
      }
    }

    .chatroom-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .chatroom-header {
      padding: 1rem 1rem 0 0;
    }
  }

  .messages-list {
    padding: 1rem;
    $messages-list-height: 16.7rem;
    min-height: $messages-list-height;
    max-height: 20.5rem;

    //Nascondiamo la scrollbar ma manteniamo scrollabile
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    //Sfumatura per nascondere i messaggi in alto
    mask-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .input-wrapper {
    padding: 1rem;
    box-shadow: 0px 0 5px -2px $color-shadow;

    .message-input-row {
      background-color: $color-white;

      display: flex;
      flex-flow: row nowrap;

      .input-container {
        flex: 1 0 auto;
      }

      .sendbutton-container {
        flex: 0 1 auto;
        flex-basis: 2rem;

        .sendbutton-button {
          padding-top: 0.5rem;
          width: 2rem;
          background: transparent;
          border: 0;
          &:hover {
            cursor: pointer;
          }

          img {
            width: 1.2rem;
          }
        }
      }

      input.input-message {
        width: 100%;
        background-color: $color-white;
        border: 0;
        border-radius: 0;
        padding: 0.5rem;
        margin: 0;
        font-size: $body-font-size-xxsmall;
        color: $color-text;
        word-break: break-word;
      }
    }
  }

  // Plenary Participants List
  .participants-list-wrapper {
    padding: 1rem 0.5rem 0 1rem;
    h2 {
      color: $color-primary;
    }
  }
}
