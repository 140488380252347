// PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT
// PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT
// PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT PHOTOBOOT

.photoboot-wrapper {
  text-align: center;
  flex: 1 0 auto;

  p {
    line-height: $body-font-size;
    span {
      font-weight: 0.7rem;
    }

    &.bold {
      font-weight: bold;
    }
  }

  a {
    color: $color-white !important;
  }
}

.photobooth-container {
  // border: 1px solid $color-gray;
  // border-radius: 0.8rem;
  // padding: 2rem 8rem;

  //background-color: $color-primary;
  //color: $color-white;

  display: flex;
  flex-direction: column;
  align-items: center;

  .photobooth-picture-box {
    border-radius: 0.8rem;

    width: 100%;
    height: 20rem;
    //max-width: 28rem;

    display: flex;
    flex-flow: column wrap;

    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;

    video {
      position: absolute;
      z-index: 0;
      max-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      max-width: 400px;
    }

    &.profilepic {
      padding: 25%;
    }
  }

  .shot-button {
    width: 5rem;
    height: 5rem;
    background-color: $color-white;
    background-image: url("/img/shot-button.png");
    background-position: top left;
    background-size: cover;
    border-radius: 50%;

    margin: 0 auto;
    border: 0;
    @include transitionall(0.2s);
    outline: none;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
    }

    & span.label {
      display: none;
    }
  }

  .frame-selector {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .style-selector-wrapper {
    width: 5rem;
    height: 5rem;
    background-image: url("/img/profilepic-back.png");
    background-size: cover;
    margin: 0 auto;
    position: relative;
    border-radius: 50%;
    @include transitionall(0.2s);
    box-sizing: content-box;
    border: 3px solid $color-primary;

    &:hover {
      cursor: pointer;
      border: 3px solid $color-white;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.frame-1:after {
      background-image: url("/img/frame_1.png");
      background-size: cover;
    }

    &.frame-2:after {
      background-image: url("/img/frame_2.png");
      background-size: cover;
    }

    &.frame-3:after {
      background-image: url("/img/frame_3.png");
      background-size: cover;
    }
  }

  .checkbox-row {
    text-align: left;
    font-style: italic;
    padding-left: 1rem;
  }

  .radio-row {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;

    .box {
      padding: 0;
    }
    .radio-container {
      padding: 0;
      float: left;
      width: 2rem;
    }
    label {
      float: left;
      padding-right: 1.2rem;
      white-space: nowrap;
      line-height: 2rem;
    }
  }

  .button,
  a.button {
    &--ghost {
      span {
        font-family: "Gotham", sans-serif;
        font-size: 0.8rem !important;
        margin-top: 0.2rem;
      }
    }
  }
}

.profile-wrapper {
  .change-photo {
    text-align: center;
    a {
      padding: 0.4rem;
      font-size: 0.65rem;
      display: inline;
      margin: 0 auto;
    }

    .button--ghost {
      // background-color: $color-white;
      // color: $color-primary !important;
      // border: 1px solid $color-gray;

      &:hover {
        color: $color-white !important;
        background-color: $color-darkgray !important;
      }
    }
  }

  h2,
  p {
    text-align: center;
  }

  .meeting-types-wrapper {
    padding-top: 1rem;
    .meeting-types {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;

      .checkbox-row {
        #outOfHomeCheckbox,
        #modernTradeCheckbox {
          margin-right: 1rem;
        }
      }
    }
  }
}
