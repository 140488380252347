// PRIVACY

// OVERRIDE SFONDO
.page-wrapper {
  .page-container {
    .page-content {
      &.privacy-themed {
        color: #000;
        font-size: 1.1rem;

        a {
          color: $color-primary;
        }
      }

      .center-area {
        &.privacy-themed {
          &:before {
            background: $color-primary;
          }
        }
      }
    }
  }
}

.privacy-wrapper {
  padding: 5rem 3rem 3rem 3rem;
  background-color: $color-white;
  line-height: 1.5rem;

  .section-container {

    h2 {
      font-weight: normal;
      text-transform: none;
      font-size: 1.2rem;
      margin-bottom: 0;
      text-transform: none;
    }

    justify-content: flex-start;
    .box.col-2 {
      //flex: 0 2 auto;
      text-align: right;
      img {
        margin: 0 0 0 auto;
      }
    }

    p {
      padding: 0.5rem 0;
      margin: 0;
    }

    .title {
      img {
        width: 1.5rem;
        vertical-align: middle;
      }
    }

  }
}
