@import "/styles/reset";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap");

@import "/styles/variables";
@import "/styles/animations";
@import "/styles/pagestructure";

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin transitionall($duration) {
  -webkit-transition: all $duration ease-in-out;
  -moz-transition: all $duration ease-in-out;
  -o-transition: all $duration ease-in-out;
  transition: all $duration ease-in-out;
}

body {
  font-family: "Gotham", sans-serif;
  color: $color-text;
  font-size: 100%;
  font-size: 16px;
  line-height: 130%;
  font-weight: 300;
}

// APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP
// APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP
// APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP APP
.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

.big-icon-container {
  position: relative;
}

.columns-wrapper .box {
  & > img.App-logo {
    position: absolute;
    margin-top: -60%;
    top: 50%;
    left: -20%;
    width: 110%;
  }
}

p {
  margin: 1rem 0;
}

a,
a:link,
a:visited {
  color: $color-white;
  text-decoration: underline;

  &:hover {
    color: $color-white;
    cursor: pointer;
  }
}

.truste-pp-container {
  a,
  a:link,
  a:visited {
    color: black;
    text-decoration: underline;

    &:hover {
      color: #C41230;
      cursor: pointer;
    }
  }
}

h1 {
  font-family: "Gotham", sans-serif;
  color: $color-primary;
  font-weight: bold;
  //text-transform: uppercase;

  font-size: 2.5rem;
  margin-bottom: 1rem;

  &.mobile-advice {
    font-family: "Gotham", sans-serif;
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: normal;
  }
}

h2 {
  font-family: "Gotham", sans-serif;
  color: $color-primary;
  font-weight: 300;
  //text-transform: uppercase;
  line-height: 1.9rem;
  font-size: 1.9rem;
}

h3 {
  font-family: "Gotham", sans-serif;

  color: $color-primary;
  font-weight: bold;
  //text-transform: uppercase;

  font-size: $body-font-size;
  margin-bottom: 0.5rem;
}

@import "/styles/navigation";
@import "/styles/photobooth";
@import "/styles/agenda";
@import "/styles/infodesk";
@import "/styles/guest";
@import "/styles/questionModal";
@import "/styles/newestVideoSection";

@import "/styles/privacy";
@import "/styles/networking";
@import "/styles/plenarychat";
@import "/styles/plenary";
@import "/styles/polloverlay";
@import "/styles/completereg";
@import "/styles/voices";

// PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE
// PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE
// PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE PROFILE

// .profilepic-container {
//     width: 7.68rem;
//     height: 7.68rem;
//     border-radius: 50%;
//     background-color: $color-input;

//     overflow: hidden;

//     img.profilepic-image {
//         width: auto;
//         max-width: 100%;
//         min-width: 100%;
//         height: auto !important;
//     }
// }

.profilepic-wrapper {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;

  border-radius: 50%;
  overflow: hidden;

  .profilepic-container {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    img.profilepic-image {
      object-fit: cover;
      object-position: 50% 50%;

      min-height: 100%;
      max-width: 100%;
    }
  }
}

// FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM
// FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM
// FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM FORM

input {
  width: 100%;
  // background-color: $color-input;
  // border: 1px solid $color-input;
  background-color: $color-gray;
  border: 1px solid $color-gray;
  border-radius: 0;
  padding: 0.8rem;
  margin: 0.5rem 0;
  font-size: $body-font-size;
  color: $color-text;
  outline: 0;
  font-family: "Gotham", sans-serif !important;
  font-weight: 300;
  font-size: $body-font-size-small;

  input:focus {
    outline: 0;
  }
}

.page-int input {
  //background-color: $color-input;
  border: 2px solid $color-input;
}

.page-int input:disabled {
  background-color: $color-input;
}

textarea {
  border: 1px solid $color-gray;
  border-radius: 0.2rem;
  width: 100%;
  height: 6rem;
  resize: none;

  font-family: "Gotham", sans-serif;
  color: $color-text;
  font-size: $body-font-size;

  padding: 1rem;
  box-shadow: 0px;
}

.checkbox-row {
  input[type="checkbox"] {
    width: 1rem;
    margin: -0.3rem 0.5rem 0 0;
    float: left;
  }
}

.radio-row {
  font-size: $body-font-size;
  margin: 1rem 1rem;

  //.radio-container {
  // display: flex;
  // flex-flow: row nowrap;
  // justify-content: space-around;
  //}

  label {
    font-size: $body-font-size;
  }
}

.button,
a.button {
  display: block;
  width: 100%;

  background-color: $color-primary;
  border-radius: 0;
  padding: 0.4rem 1.4375rem;
  margin: 0.5rem 0;

  font-size: $body-font-size-small;
  color: $color-white;
  text-transform: uppercase;
  font-weight: 900;
  text-decoration: none;
  text-align: center;
  outline: none;

  cursor: pointer;

  @include transitionall(0.2s);

  &:hover {
    color: $color-white;
    background-color: darken($color-primary, 10);
  }

  &.reverse {
    color: $color-text;
    background-color: $color-white;
    border: 1px solid $color-white;
    cursor: pointer;
  }

  &.gray {
    color: $color-primary;
    background-color: $color-gray;
    border: 1px solid $color-gray;
    cursor: pointer;

    &:hover {
      color: $color-primary;
      background-color: $color-white;
      border: 1px solid $color-primary;
      cursor: pointer;
    }
  }

  &--submit {
    @extend .button;
  }

  &--submit_small {
    @extend .button;
    padding: 0.5rem 1rem;
  }

  &--ghost {
    @extend .button;
    background-color: $color-white;
    color: $color-darkgray;
    border-color: $color-darkgray;
    &:hover {
      color: $color-white;
      background-color: $color-darkgray;
    }
  }

  &--alternate {
    @extend .button;
    background-color: #000;
    border-color: #000;
  }

  &--select {
    @extend .button;
    background-color: $color-gray;
    color: $color-darkgray;
    border-color: $color-gray;
  }

  span {
    display: block;
    text-transform: none;
    font-weight: normal;
    font-size: $body-font-size;
  }
}

@media screen and (max-width: 1199.98px) {
  .button,
  a.button {
    padding-left: 0;
    padding-right: 0;
  }
}

.button-mini,
a.button-mini {
  display: block;

  background-color: $color-mediumgray;
  border: 1px solid $color-mediumgray;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;

  font-size: 0.625rem;
  color: $color-white;
  font-weight: bold;
  text-decoration: none;
  text-align: center;

  outline: none;

  @include transitionall(0.2s);

  &:hover {
    color: $color-primary;
    background-color: $color-white;
    border: 1px solid $color-primary;
    cursor: pointer;
  }
}

.form-separator {
  width: 100%;
  height: 1px;
  border-top: 1px solid $color-input;
  margin: 1rem 0;
}

.form-spacer {
  width: 100%;
  height: 1px;
  margin: 1rem 0;
}

.info {
  font-family: "Gotham", sans-serif;
  color: #000;
  font-size: 1rem; // 0.625rem;
  //font-style: italic;
  padding-top: 0.5rem;

  ul {
    padding-left: 1rem;

    li {
      list-style-type: square;
      line-height: 1rem;
    }
  }
}

.help-link,
.help-link a {
  font-family: "Gotham", sans-serif;
  color: $color-white;
  font-size: $body-font-size;
  text-align: right;
  padding: 1rem 0;
  position: relative;
}

.message-error {
  font-family: "Gotham", sans-serif;
  color: $color-primary;
  font-size: $body-font-size;
  text-align: center;
  font-weight: bold;

  width: 100%;
  padding: 0 2rem;
  margin: 0.5rem 0;
  border-top: 1px dotted $color-primary;
  border-bottom: 1px dotted $color-primary;

  .error-string {
    padding: 1rem 0;
    color: $color-white;
  }
}

/**
* Make the field a flex-container, reverse the order so label is on top.
*/

.field {
  display: flex;
  flex-flow: column-reverse;
  background-color: $color-white;
  border: 2px solid $color-input;
  border-radius: 0.2rem;
  margin: 0.5rem 0;

  &.disabled {
    background-color: $color-input !important;
    color: $color-text;
    input {
      color: $color-text;
    }
  }

  /**
  * Add a transition to the label and input.
  * I'm not even sure that touch-action: manipulation works on
  * inputs, but hey, it's new and cool and could remove the 
  * pesky delay.
  */
  label,
  input,
  textarea {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  input,
  textarea {
    border: 0;
    -webkit-appearance: none;
    border-radius: 0;
    cursor: text;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0.5rem;
  }

  input:focus,
  textarea:focus {
    outline: 0;
  }

  label {
    color: $color-text;
    font-size: 0.7rem;
    line-height: 0.8rem;
    padding: 0.5rem 1rem 0 1rem;
  }
  /**
  * Translate down and scale the label up to cover the placeholder,
  * when following an input (with placeholder-shown support).
  * Also make sure the label is only on one row, at max 2/3rds of the
  * field—to make sure it scales properly and doesn't wrap.
  */
  input:placeholder-shown + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.5);
  }
  /**
  * By default, the placeholder should be transparent. Also, it should 
  * inherit the transition.
  */
  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }
  /**
  * Show the placeholder when the input is focused.
  */
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    opacity: 1;
  }
  /**
  * When the element is focused, remove the label transform.
  * Also, do this when the placeholder is _not_ shown, i.e. when 
  * there's something in the input at all.
  */
  input:not(:placeholder-shown) + label,
  input:focus + label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
  }
}

.help-toggle {
  font-family: "Gotham", sans-serif;
  color: $color-white;
  font-size: $body-font-size;
  text-align: right;
  font-style: normal;
  padding: 1rem 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.modal-wrapper {
  position: absolute;
  z-index: 1000;

  background-color: $color-white;
  border: 1px solid $color-gray;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);

  padding: 1rem;
  width: 100%;
  opacity: 0;
  color: #000;
  font-weight: normal;

  &.visible {
    &.left {
      left: 0;
      bottom: 0;
      animation: modal-show-left 0.2s forwards;
    }

    &.right {
      left: 0;
      top: 1rem;
      animation: modal-show-right 0.2s forwards;
    }
    transform: translate(0);
  }

  &.hidden {
    &.left {
      animation: modal-hide-left 0.2s forwards;
      transform: translate(500rem);
    }

    &.right {
      animation: modal-hide-right 0.2s forwards;
      transform: translate(500rem);
    }
  }

  .modal-close {
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid $color-gray;
    border-radius: 1rem;
    font-size: 0.8rem;
    line-height: 1.2rem;
    text-align: center;
    font-style: normal;
    font-family: Arial;
    color: $color-mediumgray;
    background-color: $color-white;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  .modal-content {
    font-family: "Gotham", sans-serif;
    color: $color-text;
    font-size: $body-font-size;
    line-height: 1.2rem;
    text-align: left;
    font-style: normal;

    h2 {
      text-transform: none;
      font-size: $body-font-size;
      color: #000;
      font-weight: normal;
    }

    a,
    a:link,
    a:visited {
      color: $color-primary;
      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    p {
      color: #000;
    }
  }
}

.big-modal-wrapper {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20%;
  text-align: left;
  z-index: 2000;

  background-color: $color-white;
  padding: 2rem;
  border: 2px solid #000;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);

  width: 50%;
  min-width: 30rem;

  opacity: 0;

  &.visible {
    animation: big-modal-show 0.5s forwards;
    transform: translate(0);
  }

  &.hidden {
    animation: big-modal-hide 0.5s forwards;
    transform: translate(-2000rem);
  }

  p {
    margin: 0;
    padding: 0;
  }
  .text-right {
    text-align: right;
    padding-right: 2rem;
    padding-bottom: 1rem;
  }

  .modal-close {
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid $color-darkgray;
    border-radius: 1rem;
    font-size: 0.8rem;
    line-height: 1.2rem;
    text-align: center;
    font-style: normal;
    font-family: Arial;
    color: $color-mediumgray;
    background-color: $color-white;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  .modal-content {
    font-family: "Gotham", sans-serif;
    color: #000;
    font-size: $body-font-size;
    line-height: 1.2rem;
    text-align: left;
    font-style: normal;

    strong {
      font-weight: bold;
    }

    h2 {
      text-align: center;
      line-height: 2.2rem;
    }

    span {
      color: $color-primary;
    }

    a.mail,
    a.tel {
      color: $color-primary;
    }
  }
}

.notification-wrapper {
  position: fixed;
  top: 5rem;
  right: 1rem;
  width: 30%;
  z-index: 1000;

  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.45);

  padding: 1rem;
  opacity: 0;

  &.visible {
    &.left {
      animation: modal-show-left 0.5s forwards;
    }

    &.right {
      animation: modal-show-right 0.5s forwards;
    }

    transform: translate(0);
  }

  &.hidden {
    &.left {
      animation: modal-hide-left 0.5s forwards;
    }

    &.right {
      animation: modal-hide-right 0.5s forwards;
    }

    transform: translate(500rem);
  }

  .notification-content {
    font-family: "Gotham", sans-serif;
    color: $color-white;
    font-size: $body-font-size * 1.3;
    line-height: $body-font-size * 1.5;
    text-align: left;
    font-style: normal;

    h2 {
      text-transform: none;
      font-size: $body-font-size;
      color: $color-text;
      font-weight: normal;
    }

    a,
    a:link,
    a:visited {
      color: $color-primary;
    }
  }
}

@import "/styles/hall";

// ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO

.pass-wrapper {
  position: relative;
  display: flex;
  // margin-bottom: 14px;
}

i {
  position: absolute;
  top: 33%;
  right: 4%;
}
i:hover {
  // color: #00fcb6;
  cursor: pointer;
}

// ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO
// ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO ALTRO

.thank-you-container {
  padding: 4rem 2rem;
}

.coming-soon {
  text-align: center;

  img {
    margin: 0 auto;
    width: 4rem;
  }

  h1 {
    padding: 2rem 0;
  }

  hr {
    border: 0;
    padding: 0;
    margin: 2rem 0;
    border-bottom: 3px solid $color-input;
  }

  h2 {
    padding: 2rem 0 1rem 0;
    color: #000;
  }

  p {
    width: 80%;
    margin: 0 auto;
  }

  a {
    color: $color-white !important;
    margin: 0 auto;
    margin-top: 3rem;
    width: 80%;

    &:hover {
      color: $color-primary !important;
    }
  }

  img.app-logo {
    max-width: 15rem;
  }
}

.tww-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tww-image {
  max-width: 80%;
}

.tww-image-small {
  max-width: 19.687rem; //315px
}

.tww-image-mobile {
  max-width: 14rem; //220px
}

.logo-talks {
}

.logo-ts {
  margin-bottom: 1.1rem;
}

// POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES
// POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES
// POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES POSITIONED IMAGES
.page-login {
  .woman-image {
    position: absolute;
    top: 10%;
    right: 15%;
    z-index: -1;
    width: 15%;
  }

  .man-image {
    position: absolute;
    bottom: 0;
    left: -5%;
    z-index: -1;
    width: 40%;
  }

  .man_small-image {
    position: absolute;
    bottom: 5%;
    right: 0;
    z-index: -1;
    width: 20%;
  }
}

.page-plenary {
  .man-image {
    position: absolute;
    bottom: 0;
    left: 62%;
    max-width: 33rem;
  }
}

.page-chatroom {
  .woman-image {
    position: absolute;
    top: 10rem;
    left: 0%;
    width: 15%;
  }
}

.page-agenda {
  .woman-image {
    position: absolute;
    top: 10rem;
    left: -5%;
    width: 20%;
  }
}
.page-infodesk {
  .man-image {
    position: absolute;
    top: 10rem;
    left: -1%;
    width: 17%;
  }
}
.page-guest {
  .man-image {
    position: absolute;
    top: 10rem;
    left: -1%;
    width: 17%;
  }
}

.page-animation-container {
  width: 17rem;
  max-width: 17rem;
}

// TOAST TOAST TOAST TOAST TOAST TOAST TOAST TOAST TOAST TOAST TOAST TOAST TOAST

@keyframes toast-open {
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0, 0);
  }
}

.toast-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  transform: translate(0, -100%);

  .rainbow {
    [class*="rc-"] {
      height: 0.5rem;
    }
  }

  &.visible {
    animation: toast-open 0.3s forwards;
  }
}

// MODAL MODAL MODAL MODAL MODAL MODAL MODAL MODAL MODAL MODAL MODAL MODAL

@keyframes modal-open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalconfirm-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: $color-overlay;
  opacity: 0;

  .rainbow {
    [class*="rc-"] {
      height: 0.5rem;
    }
  }

  &.visible {
    animation: modal-open 0.3s forwards;
  }
}

.modalbox-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: $color-overlay;
  opacity: 0;

  .rainbow {
    [class*="rc-"] {
      height: 0.5rem;
    }
  }

  &.visible {
    animation: modal-open 0.3s forwards;
  }

  & .modalbox-content {
    color: $color-bodytext;
    a,
    a:link,
    a:visited {
      color: $color-primary;
    }
  }
}

// AUDIO

.audio-toggle {
  cursor: pointer;
}

//VIDEO JS ////////////////////////////////////////////////////////

.vjs-modal-dialog .vjs-modal-dialog-content {
  padding: 0 !important;
}
.video-js .vjs-control.vjs-close-button {
  display: none !important;
}
.video-js .vjs-big-play-button {
  display: none !important;
}

// CHECKBOXES

input[type="checkbox"] {
  /* remove browser chrome */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  /*add styling */
  position: relative;
  width: 1rem;
  height: 1rem;
  border: 0;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  color: #fff;
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  background-color: transparent;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 2px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  /* base64 encoded to make things easier to show 
  	normally you would use an image or a font
  */
  background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjQ0OCIgaGVpZ2h0PSI0NDgiIHZpZXdCb3g9IjAgMCA0NDggNDQ4Ij4KPHRpdGxlPjwvdGl0bGU+CjxnIGlkPSJpY29tb29uLWlnbm9yZSI+CjwvZz4KPHBhdGggZD0iTTQxNy43NSAxNDEuNWMwIDYuMjUtMi41IDEyLjUtNyAxN2wtMjE1IDIxNWMtNC41IDQuNS0xMC43NSA3LTE3IDdzLTEyLjUtMi41LTE3LTdsLTEyNC41LTEyNC41Yy00LjUtNC41LTctMTAuNzUtNy0xN3MyLjUtMTIuNSA3LTE3bDM0LTM0YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsNzMuNSA3My43NSAxNjQtMTY0LjI1YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsMzQgMzRjNC41IDQuNSA3IDEwLjc1IDcgMTd6Ij48L3BhdGg+Cjwvc3ZnPgo=");
}

input[type="checkbox"]:checked::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
  -webkit-appearance: none !important;
}

.error-message {
  color: $color-primary;
  font-size: 0.9rem;
  display: block;
  width: 100%;
}

.mb {
  margin-bottom: 1rem;
}

.scrollbar-dark {
  /* Scrollbar styles */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: $color-darkblue;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-darkblue;
  }
}

.scrollbar-light {
  /* Scrollbar styles */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-gray;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-gray;
  }
}

.smal-scrollbar-light {
  /* Scrollbar styles */
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $color-gray;
    opacity: 50%;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $color-gray;
    opacity: 50%;
  }
}


a.menu-link {
  text-decoration: none;
}

.coppetta-wrapper {
  padding-right: 30px;
}




      // CHAT MESSAGES
      //
      .messages-wrapper {

        .message {
          width: 85%;
          margin: 0 auto;
          background-color: #707070;
          color: #000;
          padding: 1.1rem 0.4rem 0.4rem 0.4rem;
          margin-bottom: 1rem;
          position: relative;
          font-size: 11px;
          line-height: 15px;
          color: #000;
          font-family: Arial;

          .user {
            font-size: 10px;
            position: absolute;
            top: 0.1rem;
            left: 0.4rem;
            color: #C8C8C8;
            font-weight: bold;
            white-space: nowrap;
            
            img {
              vertical-align: middle;
              float: left;
            }
          }

          .time {
            font-size: 10px;
            position: absolute;
            top: 0.1rem;
            right: .4rem;
            color: #C8C8C8;
          }

          &:before {
            content: " ";
            width: 0;
            height: 0;
            border-top: 20px solid #707070;
            border-left: 20px solid transparent;
            position: absolute;
            top: 0px;
            left: -20px;
          }

          &.my-message {
            background-color: #C8C8C8;

            .user {
              color: $color-primary;
              left: auto;
              right: .4rem;
              padding-right: 0;
            }

            .time {
              left: .4rem;
              right: auto;
              color:#707070;
            }

            &:before {
              display: none;
            }

            &:after {
              content: " ";
              width: 0;
              height: 0;
              border-top: 20px solid #C8C8C8;
              border-right: 20px solid transparent;
              position: absolute;
              top: 0px;
              right: -20px;
            }
          }

          &.mf-message {
            background-color: #fff;
            //border: 1px solid $color-primary;

            .user {
              color: $color-primary;
              padding-right: 0;
            }

            .time {
              color: $color-primary;
            }

            &:before {
              border-top: 20px solid #fff;
            }


            .replied-message {
              position: relative;
              padding: 1.1rem 0.4rem 0.4rem 0.4rem;
              color:#707070;
              margin: .2rem 0;
              background-color: #C8C8C8;
              border-radius: 5px;
              border-left: 3px solid #C41230;
              font-style: italic;

              .user {
                color: $color-primary;
                font-style: normal;
              }
  
              .time {
                color: #707070;
                font-style: normal;
              }
            }
  
          }
        }

        &:first-child {
          //margin-top:3rem;
        }        
      }

      // CHAT MESSAGE EDITOR
      //
      .message-edit-wrapper {
        position: relative;

        &:before {
          content: " ";
          top: 458px;
          width: 100%;
          height: 20px;
          background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
          opacity: 0.7;
          position: absolute;
          top:-20px;
          left: 0;
        }
      }


      .message-edit {
        background: #e0e0e0 0% 0% no-repeat padding-box;
        border: 1px solid #e0e0e0;
        border-radius: 0px 0px 5px 5px;
        padding: 1rem;

        .button {
          &--submit {
            margin: 0.5rem 0;
          }
        }
      }


/* REACTIONS*/

      .reaction-outer {
        display: block;
        position: absolute;
        left: -80px;
        top: -20px;
        z-index: 0;
      }
  
      .reaction-inner {
        display: inline-block;
        position: relative;
        width: 60px;
        height: 60px;
        margin: 0 10px;
        z-index: 0;
        background-color: transparent;
        background-size: contain;
      }
  
      .reaction1 {
        background-image: url('https://media.borntofarm.masseyferguson.com/reactions/reaction-1.png');
      }
  
      .reaction2 {
        background-image: url('https://media.borntofarm.masseyferguson.com/reactions/reaction-2.png');
      }
      .reaction3 {
        background-image: url('https://media.borntofarm.masseyferguson.com/reactions/reaction-5.png');
      }
  
      .reaction4 {
        background-image: url('https://media.borntofarm.masseyferguson.com/reactions/reaction-4.png');
      }
  
      .reaction5 {
        background-image: url('https://media.borntofarm.masseyferguson.com/reactions/reaction-3.png');
      }
  
      
  









// @media screen and (max-width: 1200px) {
//   .counterWR {
//     .clockItem {
//       width: 130px!important;
//       .clockSVG {
//         width: 130px!important;
//         height: 130px!important;
//       }
//       .clockNum {
//         font-size: 80px;
//       }
//       .clockLabel {
//         font-size: 20px;
//       }
//     }
//   }
// }

// @media screen and (max-width: 900px) {
//   .counterWR {
//     .clockItem {
//       .clockNum {
//         font-size: 30px;
//       }
//       .clockLabel {
//         font-size: 15px;
//       }
//     }
//   }
// }


// @media screen and (max-width: 600px) {
//   .counterWR {
//     width: 100vw;
//     margin-left: -2rem!important;
//     .clockItem {
//       width: 70px!important;
//       .clockSVG {
//         width: 70px!important;
//       }
//       .clockNum {
//         font-size: 20px;
//       }
//       .clockLabel {
//         font-size: 10px;
//       }
//     }
//   }
// }

// COMMON

@import "/styles/common";

// MEDIAQUERIES
@import "/styles/mediaqueries";
