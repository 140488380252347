// NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION
// NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION
// NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION

$navigation-width: 100vw;

@keyframes nav-open {
  0% {
    transform: translate(-$navigation-width);
    height: 100%;
  }

  99% {
    transform: translate(0);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes nav-close {
  0% {
    transform: translate(0);
    height: 100%;
  }

  99% {
    transform: translate(-$navigation-width);
    height: 100%;
  }

  100% {
    transform: translate(-$navigation-width);
    height: 0rem;
  }
}

// MOBILE NAVIGATION 
.navigation-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 40rem;
  height: 100%;
  z-index: 1000;

  display: flex;
  flex-flow: row wrap;
  align-items: stretch;

  color: $color-white;
  transform: translate(-$navigation-width);

  &.open {
    animation: nav-open 0.5s forwards;
    height: 100%;
    transform: translate(0);
  }

  &.close{
    //animation: nav-close 0.5s forwards;
    transform: translate(-$navigation-width);
    height: 0rem;
  }

  .nav-help {
    padding-left: 2rem;
    margin-top: 1rem;
    font-weight: bold;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    .nav-item-handle {
      font-size: 1.1rem !important;
    }
  }

  .navigation-container {
    width: $navigation-width;
    background-color: $color-darkblue;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.35);

    .logout {
      color: $color-orange;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 300;
    }    

    .nav-utils {
      background-color: #000;

      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;

      padding: 0.5rem 1rem;
      font-size: 0.625rem;

      img {
        height: 14px;
        width: 14px;
      }

      a {
        text-decoration: none;
        color: $color-white;
        padding-left: 1rem;
        font-size: $body-font-size;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .nav-message {
      background-color: #92182b;
      font-size: 1.875rem;
      font-weight: bold;

      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem 1rem;

      font-family: "Gotham", sans-serif;

      img {
        margin-right: 1rem;
      }
    }

    .nav-logo {
      padding: 1.7rem 1rem 1rem 1rem;

      img {
        margin: 0 auto;
        display: block;
        max-width: 80%;
      }
    }

    .nav-navigation {
     

      .nav-item-container {
        @include transitionall(0.2s);
        font-weight: 300;
        color: $color-white;

        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;

        // .icon {
        //   display: inline;
        // }
        // .icon-hover {
        //   display: none;
        // }

        // &:hover {
        //   cursor: pointer;
        //   color: $color-primary;
        //   background-color: #fff;
        //   padding-left: 0.5rem;

        //   .icon {
        //     display: none;
        //   }
        //   .icon-hover {
        //     display: inline;
        //   }
        // }

        .nav-item-handle {
          text-decoration: none;
        }

        img {
          max-width: 1.4rem;
        }
      }
    }

    .nav-spacer {
      width: 100%;
      height: 1px;
      margin: 2rem 0 0 0;
    }
  }
}

.header {
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;

  z-index: 1000;

  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  background-color: $color-darkblue;

  .nav-utils {
    font-size: 0.625rem;
    font-weight: bold;
    flex-basis: 100%;

    .nav-left {
      margin: 0;
      padding: 0;

      .userprofile-name {
        font-size: 1.125rem;
        text-decoration: none;
        color: $color-white;
      }

      .userprofile-link {
        font-size: 1.125rem;
        text-decoration: none;
        color: $color-white;

        &:hover {
          text-decoration: underline;
        }
      }

      .logout {
        color: $color-orange;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 300;
        padding-left: 1rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .nav-right {
      //float: right;
      img {
        height: 14px;
        width: 14px;
      }

      .ml {
        margin-left: 1rem;
      }

      a,
      a:visited {
        @include transitionall(0.1s);
        text-decoration: none;
        color: $color-white;
        font-size: $body-font-size-small; //14px
        padding-left: 2rem;
        font-weight: 300;

        &:first-child {
          padding-left: 0;
        }

        &:hover {
          color: $color-magenta;

        &.hall-link{
          color: $color-white;
        }
        &.plenaria-link{
          color: $color-primary-alt;
        }
        &.chat-link{
          color: $color-magenta;
        }
        &.agenda-link{
          color: $color-lily;
        }
        &.infodesk-link{
          color: $color-yellow;
        }
        &.guest-link{
          color: $color-orange;
        }
        }
      }

      a.active{
        color: $color-magenta;
        
        &.hall-link{
          color: $color-white;
        }
        &.plenaria-link{
          color: $color-primary-alt;
        }
        &.chat-link{
          color: $color-magenta;
        }
        &.agenda-link{
          color: $color-lily;
        }
        &.infodesk-link{
          color: $color-yellow;
        }
        &.guest-link{
          color: $color-orange;
        }
      }
    }

    .navigation-toggle {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;
      width: 2.8rem;

      .nav-toggle-handle {
        padding-left: 0.5rem;
        padding-top: 0.25rem;
      }

      img {
        display: block;
        width: 2.8rem;
        height: auto;
        margin-right: 0.3rem;
        &:hover {
          cursor: pointer;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
