// HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL
// HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL
// HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL

.page-hall{
  .animations-container {
    // border: 1px solid yellow;
    height: 100% !important;

    .animation-box {
      // border: 1px solid green;
      height: 100% !important;
      display: flex;
      justify-content: left;
      align-items: center;
      
      .top-center {
        // border: 1px solid white;
        width: 19.687rem;
        max-width: 19.687rem;
        position: absolute!important;      
        top: 5%;
        left: 50% !important;
        transform: translate(-50%,0) !important;
      }
    }
      .animations-wrapper {
        // border: 1px solid red;
        flex-grow: 10;
        min-height: 45rem;
        position: relative;
        display: block;

        .button-wrapper {
          position: relative;
          height: 100%;
          border: 1px solid black;
          background-color: yellow;
        }

        .animation {
          position:absolute;      
        }

        .nav-button {
          border-width: 4px;
          border-style: solid;
          background: transparent;
          text-transform: uppercase;
          position: absolute;
          text-decoration: none;
          cursor: pointer;
          padding:.2rem .2rem;
          font-size: $body-font-size-xsmall;
          font-weight: bold;
          width: 8.5rem;
          text-align: center;

          @include transitionall(0.2s);      
        }

        // Help desk
        .helpdesk {
          // border: 1px solid yellow;
          width: 30%;
          max-width: 30%;
          z-index: 9999;
          top: 8%;
          left: 1.8%;

          .nav-button{
            top: 74%;
            left: -6%;
            border-color: $rc-7;
            color: $rc-7;
          }

          &:hover{
            .nav-button{      
              background-color: darken($rc-7, 15%);
              color: $color-white;
            }
          }
        }

        // Chat
        .chat {
          // border: 1px solid pink;
          width: 25%;
          max-width: 25%;
          z-index: 9999;
          top: 6%;
          left: 73%;

          .nav-button{
            top: 70%;
            right: -7%;
            border-color: $rc-4;
            color: $rc-4;
          }

          &:hover{
            .nav-button{      
              background-color: darken($rc-4, 15%);
              color: $color-white;
            }
          }
        }

        // Plenaria
        .plenaria {
          // border: 1px solid cyan;
          width: 50%;
          max-width: 50%;
          top: 25%;
          left: 25%;

          .nav-button {
            top: 35%;
            left: 27%;
            border-color: $rc-2;
            color: $rc-2;
          }

          &:hover{
            .nav-button {      
              background-color: darken($rc-2, 15%);
              color: $color-white;
            }
          }
        }

        // Ospite
        .ospite{
          // border: 1px solid orange;
          width: 22%;
          max-width: 22%;
          top: 65%;
          left: 2%;   
          
          .nav-button{
            top: 40%;
            left: 52%;
            border-color: $rc-6;
            color: $rc-6;
          }

          &:hover{
            .nav-button{      
              background-color: darken($rc-6, 15%);
              color: $color-white;
            }
          }
        }

        // Agenda
        .agenda {
          // border: 1px solid purple;
          width: 28%;
          max-width: 28%;
          top: 68%;
          left: 72%;  
          
          .nav-button {
            top: 74%;
            left: -8%;
            border-color: $color-lily;
            color: $color-lily;
          }

          &:hover{
            .nav-button {      
              background-color: darken($color-lily, 15%);
              color: $color-white;
            }
          }
        }
    }
  }

  .event-date{
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    margin: .5rem 0;
  }
}