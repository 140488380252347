// INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK
// INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK
// INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK INFODESK



.page-infodesk {
  .info-tab{
    cursor: pointer;

    .tab-title{
      font-size: $body-font-size;
      line-height: $body-font-size * 1.3;
      font-weight: 600;
      width: 100%;
    }

    .arrow {
      min-width: 1rem;
    }


    &:hover,
    &.active{
      color: $color-yellow;

      .arrow {
          min-width: 1rem;
          background-image: url("/img/yellow-arrow-left.svg");
          background-repeat: no-repeat;
          background-position: center right;
        }

    }
  }

  .info-row{
    .info-description{
      
    }
  }

  .info-audio {
    position: absolute;
    top: .5rem;
    right: .5rem;
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .info-description {
    font-weight: 400;
    word-wrap: break-word!important;
    -webkit-hyphens: manual;

    ul {
      margin-left: 1.3rem;
      list-style: disc;
    }

    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
    
      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;
    
      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;  
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
