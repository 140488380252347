@keyframes modal-show-right {
  0% {
    transform: translate(500rem);
    opacity: 0;
  }

  99% {
    transform: translate(0);
    opacity: 1;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes modal-hide-right {
  0% {
    transform: translate(0rem);
    opacity: 1;
  }

  99% {
    transform: translate(500rem);
    opacity: 0;
  }

  100% {
    transform: translate(500rem);
    opacity: 0;
  }
}

@keyframes modal-show-left {
  0% {
    transform: translate(-500rem);
    opacity: 0;
  }

  99% {
    transform: translate(0);
    opacity: 1;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes modal-hide-left {
  0% {
    transform: translate(0rem);
    opacity: 1;
  }

  99% {
    transform: translate(-500rem);
    opacity: 0;
  }

  100% {
    transform: translate(-500rem);
    opacity: 0;
  }
}

@keyframes big-modal-show {
  0% {
    transform: translate(2000rem);
    opacity: 0;
  }

  99% {
    transform: translate(0);
    opacity: 1;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes big-modal-hide {
  0% {
    transform: translate(0rem);
    opacity: 1;
  }

  99% {
    transform: translate(-2000rem);
    opacity: 0;
  }

  100% {
    transform: translate(-2000rem);
    opacity: 0;
  }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}