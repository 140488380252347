@media screen and (max-width: 1400px) {
  // HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL

  .page-hall {
    // Helpdesk
    .animations-container {
      .animations-wrapper {
        .helpdesk {
          .nav-button {
            top: 72% !important;
            left: -15%;
          }
        }

        // Chat
        .chat {
          .nav-button {
            top: 70%;
            right: -18%;
          }
        }

        // Plenaria
        .plenaria {
          .nav-button {
            top: 35%;
            left: 20%;
          }
        }

        // Ospite
        .ospite {
          .nav-button {
            top: 38%;
          }
        }

        // Agenda
        .agenda {
          .nav-button {
            left: -22%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  // HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL
  .page-hall {
    // Helpdesk
    .animations-container {
      .animations-wrapper {
        .helpdesk {
          .nav-button {
            top: 70% !important;
            left: -23%;
          }
        }

        // Chat
        .chat {
          .nav-button {
            top: 75%;
            right: -25%;
          }
        }

        // Plenaria
        .plenaria {
          .nav-button {
            top: 30%;
            left: 17%;
          }
        }

        // Ospite
        .ospite {
          .nav-button {
            top: 36%;
          }
        }

        // Agenda
        .agenda {
          .nav-button {
            left: -25%;
          }
        }
      }
    }
  }

  // POLL POLL POLL POLL POLL POLL POLL POLL POLL

  .poll-overlay-container {
    .poll-close-button {
      font-size: 1.5rem;
      top: 2rem;
      right: 0%;

      a,
      a:visited {
        line-height: 1rem;
        img {
          width: 1.5rem;
        }
      }
    }

    .poll-container {
      .box {
        padding: 0.3rem 0.3rem;
      }
      .poll-question {
        .text-wrapper {
          font-size: 1rem;
        }
      }
      .poll-answer {
        .text-wrapper {
          font-size: 0.8rem;
          border: 3px solid $color-magenta;
          padding: 0.2625rem 1.4375rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .tww-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .people-image {
    display: none !important;
  }

  // HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL
  .page-hall {
    // Helpdesk
    .animations-container {
      .animations-wrapper {
        .helpdesk {
          .nav-button {
            top: 70% !important;
            left: -20%;
          }
        }

        // Chat
        .chat {
          .nav-button {
            top: 80%;
            right: -22%;
          }
        }

        // Plenaria
        .plenaria {
          .nav-button {
            top: 35%;
            left: 17%;
          }
        }

        // Ospite
        .ospite {
          .nav-button {
            top: 36%;
          }
        }

        // Agenda
        .agenda {
          .nav-button {
            left: -22%;
          }
        }
      }
    }
  }

  .top-header {
    .event-date {
      margin-bottom: 0.5rem;
    }
  }

  // LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN
  .page-login {
    .man-image {
      display: none;

      max-width: 25rem;
      position: fixed;
      bottom: 4.6rem;
      left: 0%;
      z-index: -1;
    }
  }
}

@media screen and (max-width: 996px) {
  .tww-container {
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    .tww-image-small {
      max-width: 14rem; //220px
    }
  }

  // HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL
  .page-hall {
    .center-area {
      .animations-container {
        width: 100%;
        max-width: 100vw;
        overflow: auto;
        display: flex;

        .animations-wrapper {
          margin-top: 1rem;
          min-height: 20rem;
          position: unset;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          .nav-button {
            position: unset;
          }

          .animation {
            text-align: center;
            position: unset;
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding: 0 1rem;
            width: unset;
            max-width: unset;

            img {
              position: relative;
              display: block;
              // width: 15rem;
              // max-width: 15rem;
              height: 10rem;
            }

            .nav-button {
              margin: 1.5rem auto 0 auto;
            }
          }
        }
      }
    }
  }

  //
  // PAGE PLENARIA
  .page-plenary {
    .questions-wrapper {
      .action-container {
        padding: 1rem;

        .start-button {
          padding: 0.5rem;
        }
      }
    }
    .video-wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  // LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN
  .page-login {
    .man-image {
      display: block;

      max-width: 25rem;
      position: fixed;
      bottom: 4.6rem;
      left: 0%;
      z-index: -1;
    }
  }

  // NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION
  .navigation-wrapper {
    .navigation-container {
      .nav-logo {
        padding: 1rem 1rem 0 1rem !important;
      }
    }

    .mobile-header {
      padding-bottom: 0.2rem !important;

      .userprofile-name {
        font-size: 1.125rem;
      }
    }

    .nav-navigation {
      .nav-item-container {
        padding-bottom: 1.2rem !important;

        .nav-item-handle {
          font-size: 1.312rem;
        }
      }
    }
  }
  // INFODESK INFODESK INFODESK INFODESK

  .page-infodesk {
    .info-tab {
      .tab-title {
        font-size: $body-font-size-xxsmall;
        line-height: $body-font-size-xxsmall * 1.3;
      }
    }

    .info-audio {
      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  // POLL POLL POLL POLL POLL POLL POLL POLL POLL

  .poll-overlay-container {
    .poll-close-button {
      font-size: 1.5rem;
      top: 2rem;
      right: 0%;

      a,
      a:visited {
        line-height: 1rem;
        img {
          width: 1.5rem;
        }
      }
    }

    .poll-container {
      .box {
        padding: 0.3rem 0.3rem;
      }
      .poll-question {
        .text-wrapper {
          font-size: $body-font-size-xsmall;
        }
      }
      .poll-answer {
        .text-wrapper {
          font-size: $body-font-size-xxsmall;
          border: 2px solid $color-magenta;
          padding: 0.2rem 0.5rem;
        }
      }
    }
  }

  //
  // PAGE PLENARIA
  .page-plenary {
    .questions-wrapper {
      flex-flow: column;
      .action-container {
        flex-basis: 100%;
        width: 100%;

        &.opened {
          padding: 0;

          .content {
            flex-flow: column;
            flex-basis: 100%;
            width: 100%;
  
            .col-6 {
              width: 100%;
            }
  
            .icon-container {
              display: none;
            }

            .info-container {
              width: 100%;
              text-align: center;
              padding-right: 0;

              .title {
                width: 100%;
                text-align: center;
              }
              .help {
                width: 100%;
                text-align: center;
                padding-bottom: .2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  // LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN
  .page-login {
    .woman-image,
    .man_small-image {
      display: none;
    }
    .man-image {
      display: block;
      position: unset;
      max-width: 16rem;
      margin: 0 auto;
    }
  }

  // ITEMS LIST SCROLLABLE
  .items-list {
    width: 90vw;
    max-width: 90vw;
    overflow: auto;
    display: flex;

    .items-list-scroller {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      & > div {
        padding-top: 1rem;
        padding-bottom: 1.3rem !important;
        width: 8.5rem;
        position: relative;

        .arrow {
          transform: rotate(90deg);
          position: absolute;
          bottom: 0;
          left: 45%;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
}
