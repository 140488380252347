.poll-overlay-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 1.5rem 0;
  background: rgb(0,0,0);
  background: linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
  
  .poll-close-button {
    // background: rgb(0, 0, 0);
    font-family: "Gotham";
    transition: all 0.2s ease;
    font-size: 2rem;
    font-weight: lighter;
    display: block;
    text-align: center;
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: 2rem;

    right: 0%;

    opacity: 0.8;
    cursor: pointer;

    svg:hover {
      fill: white;
    }

    a,
    a:visited {
      display: block;
      color: white;
      text-transform: uppercase;
      // font-size: 0.6rem;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      line-height: 1.3rem;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: $color-magenta;
      }
    }

    &:hover {
      opacity: 1;
      // border: 2px solid $color-primary;
    }
  }

  .poll-container {
    display: flex;
    flex-flow: row wrap;

    .box {
      padding: 0.5rem 0.5rem;
    }
    .poll-question {
      .text-wrapper {
        font-family: "Gotham";
        font-weight: 800;
        font-size: 1.2rem;
        text-decoration: none;
        padding: 0.4rem 1rem 0rem 0rem;
        color: white;
      }
    }
    .poll-answer {
      .text-wrapper {
        display: block;
        font-family: "Gotham", sans-serif;
        font-weight: 900;
        font-size: 1rem;
        background: transparent;
        border: 4px solid $color-magenta;
        padding: 0.2625rem 1.4375rem;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        color: $color-magenta;
        cursor: pointer;

        &:hover {
          text-decoration: none;
          background: $color-magenta;
          color: $color-white;
        }
      }
    }
  }
}
