body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Effra Corp", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/Gotham-Medium.otf");
  font-style: normal;
  font-weight: medium;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/Gotham-Light.otf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/Gotham-Book.ttf");
  font-style: normal;
  font-weight: 350;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/Gotham-Bold.otf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

.select:focus {
  outline: none;
  border-radius: 0px;
}

/* slider */

.slick-dots {
  bottom: -3.5rem !important;
}
.slick-dots li {
  margin-right: 50px !important;
}

.slick-dots li button::before {
  background-color: white;
  width: 50px !important;
  height: 5px !important;
  content: "" !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button:before {
  background-color: #c41230;
}
.slick-track {
  margin-left: 0 !important;
}
